<template>

  
  
    <section id="footer">

      <div class="contenuto">

        <p>
          I framework e le librerie utilizzate per questo sito sono: VueJS2, Nodejs, Bootstrap, AOS.
        </p>

      </div>
           
    </section>
  
  
</template>
  
<script>

export default {
    name: 'FooterVue',
    props:{
      Selected: Boolean,
    }
    
}
  
  </script>
  
  
  <style lang="scss" scoped>
  
 #footer{
  height: 10vh;
  margin: auto;
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  flex-wrap: nowrap;

  .contenuto{
    width: 80%;
    color: white;
    text-align: center;
    p{
      font-size: 15px;
      margin: 0;
    }
  }
  
 
}

@media screen and (max-width: 600px) {
  #footer{
  height: 10vh;
  margin: auto;
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  flex-wrap: nowrap;

  .contenuto{
    width: 80%;
    color: white;
    text-align: center;
    p{
      font-size: 12px;
      margin: 0;
    }
  }
}

}
  </style>