<template>


    <section id="form">
      <h3>CONTATTAMI</h3>
        <div class="text-center" data-aos="fade-up" data-aos-duration="3000">

          <p>mail:</p> 
          <h6>leonardo.giulio.pellegrini@gmail.com</h6>
          <a href="mailto:leonardo.giulio.pellegrini@gmail.com">
            <p :class="{ black : Selected }">Invia una mail</p>
          </a>

        </div>

    </section>
</template>

<script>

  export default {
    name: 'FormVue',
    props:{
      Selected: Boolean,
    },
  }
</script>
  
  <style lang="scss" scoped>
  
 #form{
  min-height: 70vh;
  width: 100%;
  padding-top:20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a{
  text-decoration: none;
  p{
  margin-top:30px;
  background-color: #dd3009;
  color: white;
  padding: 10px 27px;
  border-radius: 35px;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 500;
  transition: all 0.5s;
  &:hover{
    background-color: #232323;
    }
  }
  .black{
    background-color: #232323;
    &:hover{
      background-color: white;
      color: #232323;;
    }
  }
 }
}
  


  </style>