<template>


<div id="skills" class="container-fluid d-flex justify-content-center align-items-center  flex-column align-content-between pt-5">

  <div>
    <div class="row d-flex align-items-center mb-5">
      <div class="col text-center">
        <h3>SKILLS</h3>
      </div>
    </div>
  </div>
    

  <div class="faq container">
        
    <div>
      <ul class="nav nav-tabs d-flex justify-content-around" role="tablist">
        <li @click="isonClick != isonClick"  class="nav-item" :key="`dati-${indice}`" v-for="(datoSk, indice) in datiSk">
          <a class="nav-link" :class="{'nav-link active' : isonClick,  'black' : Selected }" data-toggle="tab"  :href="`#` + datoSk.link">{{datoSk.title}}</a>
        </li>
      </ul>
    </div>
    
    <div class="tab-content" data-aos="flip-up"  data-aos-duration="2000">
      <div :key="`dati-${indice}`" v-for="(datoSk, indice) in datiSk" data-toggle="tab" :class="{'flipInX active' : isonClick}" :id="datoSk.link"  class="container tab-pane fade wrappa"><br>
        <p>{{datoSk.text}}</p>
        <div  class="loghi" :key="`logos-${indix}`"  v-for="(logo, indix) in datoSk.loghi">

          <img :src="logo.img" :alt="logo.nome">
          <h5>{{logo.nome}}</h5>
          
        </div>

      </div>
    </div>
            

  </div>

</div>

</template>

<script>

export default {
  name: 'SkillsVue',
  props:{
    Selected: Boolean,
  },
  data(){
    /////   
    return {
      isonClick: false,
      contaDati: 0,
      datiSk:[
        {
          title: "Grafica",
          link: "grafica",
          loghi:[
            {
              nome: 'Adobe Illustrator',
              img: require('../img/loghi-skills/grafica/Illustrator.png'), 
            },
            {
              nome: 'Adobe Photoshop',
              img: require('../img/loghi-skills/grafica/Photoshop.png'), 
            },
            {
              nome: 'Adobe Premiere',
              img: require('../img/loghi-skills/grafica/Premiere.png'), 
            },
            {
              nome: 'Final Cut',
              img: require('../img/loghi-skills/grafica/Final-Cut.png'), 
            },
            {
              nome: 'Mailchimp',
              img: require('../img/loghi-skills/grafica/Mailchimp.png'), 
            },
            {
              nome: 'Wordpress',
              img: require('../img/loghi-skills/grafica/wordpress.png'), 
            },
          ],
        },
        {
          title: "Programmazione Web",
          link: "programmazione-web",
          loghi:[
            {
              nome: 'HTML',
              img:  require('../img/loghi-skills/programmazione/HTML.png'), 
            },
            {
              nome: 'CSS',
              img:  require('../img/loghi-skills/programmazione/CSS.png'),
            },
            {
              nome: 'Javascript',
              img: require('../img/loghi-skills/programmazione/JS.png'), 
            },
            {
              nome: 'VueJS',
              img: require('../img/loghi-skills/programmazione/VueJS.png'),
            },
            {
              nome: 'NODEJS',
              img:  require('../img/loghi-skills/programmazione/NodeJS.png'),
            },
            {
              nome: 'MYSQL',
              img:  require('../img//loghi-skills/programmazione/MySQL.png'), 
            },
          ],
        },
        
      ]
    }
  }
}

</script>


<style lang="scss" scoped>

#skills{
  margin-bottom: 60px;
  min-height: 80vh;
  li{
    a{
      color: #232323;
      background-color: white;
      border-radius: 25px;
      margin-bottom: 20px;
      &:hover, &:focus{
        color: white;
        background-color: rgb(204, 53, 15);
        border: none;
      }
    }
    .black{
      &:hover, &:focus{
        color: white;
        background-color: #232323;
        border: none;
      }
    }
  }
  ul{
    border: none;
  }

  .wrappa{
    text-align: center;
  }
    .loghi{
    display: inline-block;
    text-align: center;
    margin: 5px;
      img{
      width: 200px;
      padding: 20px;
      }
  }


  @media screen and (max-width: 600px) {
  .loghi{    
      img{
        width: 100px;
      }
  }
 }
}

iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

</style>
