<template>
  
    <section id="chisono">

      <div class="row">
        <div class="col">
          <div class="text-center">
                <h3 class="page-section-heading text-center text-uppercase mb-2">CHI SONO</h3>
            </div>
        </div>
      </div>
            
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-6 p-1 immagine">
          <div data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease">
              <img src="../img/ritaglio-buono-webneww.png" alt="Chi Sono LP">
           </div>
        </div>
               
        <div class="col-md-6 p-2" data-aos="fade-right" data-aos-duration="2000" data-aos-easing="ease">
            <p>
              <b>Leonardo Pellegrini</b>, classe 1998.<br>
              Dopo il Diploma in Grafica e Comunicazione, ho vissuto e lavorato per un anno in <b>Australia</b>.<br>
              Al mio ritorno in Italia, ho lavorato come <b>Web Designer</b> per un’agenzia pubblicitaria.<br>
              Successivamente ho deciso di frequentare un corso di formazione per diventare <b>Sviluppatore Web</b>.
            </p>
                    
        </div>
             
    </div>

  </section>
  
</template>
  
<script>
  
  export default {
    name: 'ProvaVue',
    data(){
      return{
        daNascosto: 1,
      }
    }
    
  }
  
</script>
  
  
<style lang="scss" scoped>
  
 #chisono{
  min-height: 90vh;
  max-width: 100%;
  padding: 26px;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p{
    width: 80%;
    margin: auto;
  }

  img{
    width: 60%;
    margin: auto;
    transition: all 0.5s;
    &:hover{
      filter: grayscale(100%);
    }
  }

  .immagine{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 400px;
  }
  p{
    font-weight: 400;
    font-size: 15px;
    b{
      font-weight: 600;
    }
  }
 }

 @media screen and (max-width: 600px) {
  #chisono{
    img{
    width: 70%;
    }

    .immagine{
    height: 300px;
  }
  }
}


</style>
  