<template>
  
  <div id="pagina">
    
    <HeaderVue @cambia="cambiaColore"/>
    <body :class="{ red : Selected }">
      
      <JumbrotronVue :Selected = Selected />
      <Chisono />
      <EsperienzaVue :Selected = Selected />
      <SkillsVue :Selected = Selected />
      <Social :Selected = Selected />
      <FormVue :Selected = Selected />
    </body>
    <FooterVue/>
      
  </div>
  
  

</template>

<script>


import HeaderVue from './components/Header.vue';
import JumbrotronVue from './components/Jumbrotron.vue';
import Chisono from './components/Chisono.vue';
import EsperienzaVue from './components/Esperienza.vue';
import Social from './components/Social.vue';
import FormVue from './components/Form.vue';
import SkillsVue from './components/Skills.vue';
import FooterVue from './components/Footer.vue';


export default {
  name: 'App',
  components:{
    JumbrotronVue,
    Chisono,
    EsperienzaVue,
    Social,
    FormVue,
    HeaderVue,
    SkillsVue,
    FooterVue,
},
 
  data(){
    return{
      Selected: false,
      showCookieBar: true
    } 
  },
  methods:{
    cambiaColore(){
      this.Selected =! this.Selected
    }
  }
}
</script>

<style lang="scss">

*{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-padding-top: 15vh;
  scroll-behavior: smooth;
}

#pagina {
  contain: paint;
}


.relative{
  position: relative;
}

a{
  color: white;
}

body{
  background-color: #111111;
  color: white;
  transition: all 0.5s;
}

.red{
  background-color: #dd3009
}



h3{
  margin: 30px 0px;
  font-family: 'Unbounded'
}

@import'~bootstrap/dist/css/bootstrap.css';

</style>
