<template>

  <div>
  
    <section id="social">

          
     <h3>MI TROVI ANCHE SU</h3>
            

      <div class="loghi" :class="{'black' : Selected }">
              
        <a target="_blank" data-aos="fade-left" data-aos-duration="1000" rel="noopener noreferrer" href="https://www.linkedin.com/in/leonardo-pellegrini/">           
          <img src="../img/linkedin.svg" alt="Linkedin">
        </a>
                 
        <a target="_blank" data-aos="fade-left" data-aos-duration="2000" rel="noopener noreferrer" href="https://github.com/leonardopellegrini-github">
          <img src="../img/github.svg" alt="Linkedin">
        </a>
               
        <a target="_blank" data-aos="fade-left" data-aos-duration="3000" rel="noopener noreferrer" href="https://www.instagram.com/seiilpadrone/">
          <img src="../img/instagram.svg" alt="Instagram">
        </a>

      </div>
           
    </section>
  
  </div>
  
  
  
  
  </template>
  
  <script>
  
  export default {
    name: 'ProvaVue',
    props:{
      Selected: Boolean,
    }
  }
  
  </script>
  
  
  <style lang="scss" scoped>
  
 #social{
  min-height: 80vh;
  margin: auto;
  width: 80%;
  padding:20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  flex-wrap: nowrap;

  .loghi{
    display: flex;
    justify-content: space-between;
    align-content: center;
    a{
      display: flex;
      justify-content: center;
      align-content: center;
    img{
      width: 60%;
      padding: 20px;
      transition: all 0.5s;
      &:hover{
          filter: drop-shadow(4px 6px 7px #dd3009);
        }
      }
    }
  }

  .black{
    a{
    img{
      &:hover{
          filter: drop-shadow(4px 6px 7px #111111);
        }
      }
    }
  }
 
}

@media screen and (max-width: 600px) {
  #social{
    .loghi{
      a{
      img{
        width: 100%;
        }
      }
    }
  }
}


  </style>