<template>

  <div class="jumbotron container text-center">

      <div class="dimens" data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease">
       
        <h1 class="logolung"> <span :class="{ black : Selected }" >L</span>EONARDO <span :class="{ black : Selected }">P</span>ELLEGRINI</h1>
  
        
      </div>
      
      <div class="dimens" data-aos="fade-left" data-aos-duration="3000" data-aos-easing="ease">

        <h2>Frontend Developer</h2>
        
      </div>

      <div class="dimensimage text-center" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease">
    
        <img src="../img/Leonardo-Pellegrini-JB.gif" alt="Leonardo Pellegrini Jb">
      </div>
    
  </div>

</template>

<script>

export default {
  name: 'JumbotronVue',
  props:{
      Selected: Boolean,
  },
}
</script>


<style lang="scss" scoped>


.jumbotron{
  height: 90vh;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  .dimens{
    height: 10vh;
    span{
      transition: all 0.5s;
      color: #dd3009;
      font-family: 'Unbounded'
    }
  .black{
    color: black;
  }
  }

  h1{
    padding-top:5vh;
    font-size: 5vh;
    font-family: 'Unbounded'
  }

  h2{
    padding-top:3vh;
    font-size: 5vh;
  }

  img{
  height: 100%;
  }

 

  .dimensimage{
  height: 70vh;
  bottom: 0;
  right: 0;
  left: 0;
  }

  .logolung{
    animation: pulse-black 2s infinite;
  }
  

  @keyframes pulse-black {
  0% {
    letter-spacing: 1px;
    transform: scale(0.95);
  }
  
  70% {
    letter-spacing: 3px;
    transform: scale(1);
  }
  
  100% {
    letter-spacing: 1px;
    transform: scale(0.95);
  }
  }

}




@media screen and (max-width: 600px) {
  .jumbotron{
  height: 80vh;
    .dimens{
     height: 20vh;
    }
    .dimensimage{
    height: 50vh;
    }
  }
}

</style>
