<template>

<header class="container-fluid sticky-top altezza">

  <div :class="{ 'red' : Selected }" class="row d-flex justify-content-center align-items-center riga">

    <div class="col-4  d-flex justify-content-center toggle">
      <input type="checkbox" @click="(cambiaColore(), $emit('cambia', Selected))">
    </div>

    <div class="col-4 d-flex justify-content-center">
      <a href="https://leonardopellegrini.com/">
        <img src="../img/Leonardo-Pellegrini-Logo.svg" alt="Logo">
      </a>
      
    </div>

    <div class="col-4 d-flex justify-content-center align-items-center">
      <button @click="menuOpen = !menuOpen" class="menu" :class="{ opened: menuOpen }" onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))" aria-label="Main Menu">
        <svg width="50" height="50" viewBox="0 0 100 100">
          <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
          <path class="line line2" d="M 20,50 H 80" />
          <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
      </button>
    </div>
    <div class="row dropdown" :class="{ 'dropdown-after' : menuOpen }">
   
    <ul class="navlist">
      <li class="navlistitem" :key="`menus-${indice}`" v-for="(meno, indice) in menu">
        <a :href="`#` + meno.link" @click="menuOpen = !menuOpen">{{meno.title}}</a>
      </li>
    </ul>
  </div>
  </div>
</header>

</template>

<script>
export default {
  name: 'HeaderVue',
  data(){
    return {
      Selected: false,
      menuOpen: false,
      menu:[
        {
          title: "Chi sono",
          link: "chisono",
        },
        {
          title: "Esperienza",
          link: "esperienza",
        },
        {
          title: "Skills",
          link: "skills",
        },
        {
          title: "Social",
          link: "social",
        },
        {
          title: "Contattami",
          link: "form",
        },
      ]
    }
  },
  methods:{
    cambiaColore(){
      this.Selected =! this.Selected
    }
  }
}
</script>


<style lang="scss" scoped>

.altezza{
  height: 10vh;
}
.riga{
  padding: 10px;
  background-color: #111111;
  transition: all 0.5s;
}

img{
  width: 75px;
}

i{
  font-size: 22px;
}


/////////////////////
.toggle{
  input {
    -webkit-appearance: none;
    position: relative;
    width: 50px;
    height: 30px;
    border-radius: 25px;
    background-color: rgb(204, 53, 15);
    
    transition: background .3s;
    outline: none;
    cursor: pointer;
    &:after {
      content: '';
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 1.25rem;
    width: 1.25rem;
    background-color: white;
    transition: left 0.5s;
    }
    &:checked {
    background-color: black;
    }
    &:checked::after {
    left: 70%;
    }
  }
}

///////////////

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: rgb(255, 255, 255);
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5;
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.dropdown {
  height: 0px;
  background-color: rgb(255, 255, 255);
  color:black;
  transition: height 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 5;
}

.dropdown-after {
  height: 200px;
  z-index: 5;
  transition: height 0.2s ease;
}

.navlist {
  width: 73%;
  list-style: none;
}

.navlistitem {
  text-transform: uppercase;
  text-align: right;
  padding: 8px;
}

.navlistitem a {
  color: #000000;
  text-decoration: none;
  font-weight: 500;
  z-index: 5;  
  font-family: 'Unbounded';
  font-weight: 500;
  transition: all 0.5s;
  &:hover {
  color: #dd3009;
  margin-right: 30px;
  }
}



@media screen and (max-width: 600px) {
  .sticky{
  height: 12vh;
  }
  .navlist {
  width: 87%;
}
}

.red{
  background-color: #dd3009
}


</style>
