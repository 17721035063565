<template>


  <div id="esperienza" >
  
    <div class="text-center">
      <h3>ESPERIENZA</h3>
    </div>
  
    <div class="pulsanti" >
      
        <a :key="`dati-${inv}`" v-for="(datoMk, inv) in datiMk" :class="{'cambiocolori' : Selected}" @click="counterSlide = inv" >
          {{datoMk.title}}<br>
          {{datoMk.time}}
        </a>
      
    </div>
  
    <div class="slider" >
      
      <div class="contenuto">
        <h3>{{datiMk[counterSlide].title}}</h3>   
        <p>{{datiMk[counterSlide].time}}</p>
        <h4>{{datiMk[counterSlide].role}}</h4>
        <p>{{datiMk[counterSlide].text}}</p>
        <div class="video" v-if="datiMk[counterSlide].video > ''">
          <iframe  :src="datiMk[counterSlide].video" frameborder="0" name="none" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        
        <div class="loghi" >
          
          <a target="_blank" :href="logo.website" :key="`logos-${dox}`"  v-for="(logo, dox) in datiMk[counterSlide].loghi">
            <img  :src="logo.img" :alt="logo.nome">
          </a>
        </div>
        
      </div>
      
      <div class="frecce">
          <a class="singola" :class="{'cambiaFreccia' : Selected}" @click="menoSlide()"><i class="fa-solid fa-chevron-left"></i></a>
          <a class="singola" :class="{'cambiaFreccia' : Selected}" @click="aumentaSlide()"><i class="fa-solid fa-chevron-right"></i></a>
      </div>
  
    </div>
  
    <div class="curriculum">
          <a href="https://leonardopellegrini.com/Leonardo-Pellegrini.pdf" target="_blank">Download CV <i class="fa-solid fa-download"></i></a>
    </div>
      
  </div>
  
  
  
</template>
  
<script>
  
export default {
      name: 'EsperienzaVue',
      props:{
        Selected: Boolean,
      },
      methods:{
        aumentaSlide(){
          this.counterSlide++;
          if(this.counterSlide > this.datiMk.length - 1){
              this.counterSlide = 0;
          }
        },
        menoSlide(){
          this.counterSlide--;
          if(this.counterSlide < 0){
            this.counterSlide = this.datiMk.length - 1;
          }
        },
      },
      data() {
          /////   
          return {
            counterSlide: 0,
            datiMk: [
              {
               title: "Boolean Carrers",
               link: "boolean",
               time: "2022 - 2022",
               role: "Junior Full Stack Web Developer Trainee",
               text: "Frequentazione di un corso intensivo per imparare i linguaggi: HTML, CSS, Javascript, VUEJS, PHP, MYSQL, LARAVEL. Attraverso lezioni teoriche ed esercizi pratici.",
               loghi: [
                  {
                  nome: "GitHub",
                  img: require("../img/github.svg"),
                  website: "https://github.com/leonardopellegrini-github"
                  },
                ],
              },
              {
               title: "Greenmarketing",
               link: "greenmarketing",
               time: "2020 - 2022",
               role: "Web Designer",
               text: "Disegno, creazione e manutenzione di siti Wordpress. Lavori grafici con l’utilizzo di Photoshop/Illustrator. Montaggio video per campagne social.",
               loghi: [
                  {
                    nome: "Ingredienti di scena",
                    img: require("../img/loghi-esperienza/ingredienti-di-scena.svg"),
                    website: "https://ingredientidiscena.it/"
                  },
                  {
                    nome: "Dehomecratic",
                    img: require("../img/loghi-esperienza/dehomecratic.svg"),
                    website: "https://dehomecratic.it/"
                  },
                  {
                    nome: "Fonois",
                    img: require("../img/loghi-esperienza/fonois.svg"),
                    website: "https://fonois.com/"
                  },
                  {
                    nome: "Bioscalin",
                    img: require("../img/loghi-esperienza/bioscalin.svg"),
                    website: "https://www.bioscalin.it/"
                  },
                  {
                    nome: "Penbyme",
                    img: require("../img/loghi-esperienza/penbyme.svg"),
                    website: "https://penbyme.com/"
                  },
                  {
                    nome: "Monitor Consulting",
                    img: require("../img/loghi-esperienza/monitor-consulting.svg"),
                    website: "https://www.monitorconsulting.it/"
                  },
                ],
              },
              {
               title: "Dominis Digital",
               link: "dominis-digital",
               time: "2020 - 2020",
               role: "Web Designer",
               text: "Disegno, creazione di siti Wordpress. Realizzazione del layout Illustrator.",
               loghi: [
                 {
                    nome: "Expotime",
                    img: require("../img/loghi-esperienza/expotime.svg"),
                    website: "https://www.expotime.it/"
                 }
                ],
              },
              {
                title: "Australia",
                link: "australia",
                time: "2019 - 2020",
                text: "Tra lavori nelle farm e nella ristorazione, è stata l'esperienza che mi ha cambiato la vita",
                video: `https://player.vimeo.com/video/746962838?h=a412fe4626`
              },
            ]
          };
      },
  }
  
</script>
  
  
<style lang="scss" scoped>
  
#esperienza{
    width: 80%;
    margin: auto;
  
  .pulsanti{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    margin: 40px 0px;
    a{
      padding: 10px;
      text-align: center;
      border-radius: 25px;
      color: white;
      margin: 10px 10px;
      background-color:#dd3009;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.5s;
      &:hover, &:active{
        background-color: white;
        color: #232323;
      }
    }
    .cambiocolori{
      color: white;
      background-color:#232323;
      &:hover, &:active{
        background-color: white;
        color: #232323;
      }
    }
    
  }
  
  .slider{
   width: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
   transition: all 0.5s;
   .contenuto{
     min-height: 500px;
     background: #232323;
     width: 90%;
     margin: auto;
     height: 100%;
     border-radius: 25px;
     padding: 20px;
     img{
       padding: 5px;
       width: 15%;
       padding: 10px;
       transition: all 0.5s;
       &:hover{
       filter: drop-shadow(4px 6px 7px #dd3009);
       }
     }
    .video{
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      iframe{
        position: absolute;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
      }
    }
        
  }
  
      
  .frecce{
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .singola{
      background: #dd3009;
      font-size: 15px;
      padding: 10px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      border-radius: 30px;
      animation: pulse 2s infinite; 
    }
    .cambiaFreccia{
      background: white;
      color: #232323;
      animation: pulsewhite 2s infinite; 
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #dd3009ac;
    }
    
    50% {
      transform: scale(1);
      box-shadow: 0 0 0 10px #dd30095b;
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #dd30095b;
    }
  }
  @keyframes pulsewhite {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.847);
    }
    
    50% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.423);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.423);
    }
  }
  }
  
  .curriculum{
    padding: 30px;
    text-align: center;
    a{
      text-decoration: none;
      padding: 10px 20px;
      border-radius: 25px;
      text-transform: uppercase;
      font-weight: 600;
      color: #111111;
      background-color: white;
    }
  }
  
  @media screen and (max-width: 600px) {
    .slider{
      min-height: 80vh;
      .contenuto{
        img{
          width: 50%;
        }
        .video{
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        margin-bottom: 50px;
        iframe{
          position: absolute;
          top: 0; left: 0;
          transform: translate(0);
          width: 100%;
          height: 100%;
        }
      }
      }
    }
  
  }
  
}
  
  
  
  </style>
  